import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Components
import Seo from '../components/Seo/Seo'
import Hero from '../components/Hero/Hero'
import Contacts from '../components/Contacts/Contacts'

const DelayDisruptionAnalysis = () => {
  const data = useStaticQuery(graphql`
    {
      wp {
        page(id: "34", idType: DATABASE_ID) {
          title
          featuredImage {
            node {
              sourceUrl
              srcSet
              altText
              mediaDetails {
                width
                height
              }
            }
          }
          contactUs {
            photo {
              sourceUrl(size: THUMBNAIL)
              srcSet(size: THUMBNAIL)
              mediaDetails {
                sizes {
                  name
                  width
                  height
                }
              }
            }
            description
            address
            email
            phone {
              title
              url
            }
            social {
              linkedin
              facebook
              twitter
            }
          }
          seo {
            title
          }
        }
      }
    }
  `)

  const { title, seo, featuredImage, contactUs } = data.wp.page

  return (
    <>
      <Seo wpSeo={seo} />
      <Hero title={title} featuredImage={featuredImage.node} />
      <Contacts {...contactUs} />
    </>
  )
}

export default DelayDisruptionAnalysis
