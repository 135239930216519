import React from 'react'
import classnames from 'classnames'
import 'react-phone-number-input/style.css'

import ParallaxBackground from '../ParallaxBackground/ParallaxBackground'
import RichText from '../RichText/RichText'
import SocialList from '../SocialList/SocialList'

import '../../styles/forms.scss'
import {
  section,
  container,
  card,
  cardImg,
  cardMeta,
  cardMetaAddress,
  form,
  formTitle,
} from './Contacts.module.scss'
import ContactForm from '../ContactForm/ContactForm'

const Contacts = ({
  photo,
  description,
  address,
  email,
  phone,
  social,
  ...props
}) => {
  const photoSize = photo.mediaDetails.sizes.filter(
    (size) => size.name === 'thumbnail'
  )[0]

  return (
    <div className={section} {...props}>
      <div className={classnames('container small', container)}>
        <div className={card}>
          <div className={cardImg}>
            <img
              src={photo.sourceUrl}
              srcSet={photo.srcSet}
              alt={photo.altText}
              width={photoSize.width}
              height={photoSize.height}
            />
          </div>
          <div className={cardMeta}>
            {description && <RichText>{description}</RichText>}
            {address && (
              <RichText className={cardMetaAddress}>{address}</RichText>
            )}
            {email && (
              <div>
                <a className={'line-link'} href={`mailto:${email}`}>
                  {email}
                </a>
              </div>
            )}
            {phone && (
              <div>
                <a className={'line-link'} href={phone.url}>
                  {phone.title}
                </a>
              </div>
            )}
          </div>
          {social && <SocialList networks={social} />}
        </div>
        <div className={form}>
          <h2 className={formTitle}>Get in Touch</h2>
          <ContactForm />
        </div>
      </div>
      <ParallaxBackground />
    </div>
  )
}

export default Contacts
