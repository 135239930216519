// extracted by mini-css-extract-plugin
export var card = "Contacts-module--card--1P+SB";
export var cardImg = "Contacts-module--cardImg--FHwLq";
export var cardMeta = "Contacts-module--cardMeta--TNsJC";
export var cardMetaAddress = "Contacts-module--cardMetaAddress--KZppc";
export var container = "Contacts-module--container--wVxen";
export var form = "Contacts-module--form--PeiaU";
export var formTitle = "Contacts-module--formTitle--zfdy4";
export var lineLink = "Contacts-module--line-link---GK7m";
export var lineLink_black = "Contacts-module--line-link_black--a635b";
export var lineLink_grey = "Contacts-module--line-link_grey--kOUEp";
export var lineLink_primary = "Contacts-module--line-link_primary--nm0Rv";
export var lineLink_secondary = "Contacts-module--line-link_secondary--JHcBB";
export var lineLink_white = "Contacts-module--line-link_white--AtW4v";
export var section = "Contacts-module--section--D79yW";